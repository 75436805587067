.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // Ajuste conforme necessário

  .loading-circle {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #3f51b5; // Altere a cor conforme necessário
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
